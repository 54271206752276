<template>
<v-container>
    <v-row>
      <v-col cols="12" class="text-center">
          <header-vue/>
      </v-col>

      <v-col
        class="mb-5"
        md="12"
      >
            <v-card>
                <v-card-title class="red--text">
                    STUDENT SCORES
                </v-card-title>
                <v-card-subtitle>
                    Enter/Update Subject Scores, Remarks + Attendance, &amp; Affective+Cognitive+Psychomotors
                </v-card-subtitle>
                <info-loader-2 v-if="fetchingContent"/>
                <v-card-text v-if="fetchingContent == false && userType === 'Teacher'">
                    <v-btn text color="info" @click="showSEForm">Scores Entry</v-btn> |
                    <v-btn text color="secondary" @click="showREForm">Remarks Entry</v-btn> |
                    <v-btn text color="success" @click="showPEForm">Psychomotors Entry</v-btn>
                    <subject-scores-modal v-if="scoresEntryForm" :accessToken="accessToken" :authToken="authToken" :school="school" :classes="classes" :terms="terms"/>
                    <premium-info v-if="scoresRemarksForm && school.plan == 'Free'"/>
                    <remark-modal v-if="scoresRemarksForm && school.plan != 'Free'" :accessToken="accessToken" :authToken="authToken" :school="school" :classes="classes" :terms="terms"/>
                    <premium-info v-if="scoresMotorsForm && school.plan == 'Free'"/>
                    <motor-modal v-if="scoresMotorsForm && school.plan != 'Free'" :accessToken="accessToken" :authToken="authToken" :school="school" :classes="classes" :terms="terms"/>
                </v-card-text>
                <v-card-text  v-if="fetchingContent == false && authToken">
                    <v-alert
                        type="info"
                        dense
                        border="left"
                        text
                        color="purple"
                        >
                        <small>Before you enter scores for students, If you have students who have left your school, kindly go to <router-link to="/students">Students Page</router-link>, click the pencil icon by the student name and set status to inactive</small>
                    </v-alert>
                    <v-alert
                        type="info"
                        dense
                        border="left"
                        text
                        color="black"
                        >
                        <small>Before you record remarks and attendance for students, kindly go to <router-link to="/terms">Terms/Session Page</router-link>, click the pencil icon by the current term and ensure that the number of days in term is correct. Student absenteeism is automatically calculated by subtracting the student attendance from the number of days in term</small>
                    </v-alert>
                </v-card-text>
                &nbsp;
            </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import HeaderVue from '../../components/Header.vue'
import SubjectScoresModal from '../../components/scores/SubjectScoresModal.vue';
import PremiumInfo from '../../components/PremiumInfo.vue';
import RemarkModal from '../../components/scores/RemarkModal.vue';
import MotorModal from '../../components/scores/MotorModal.vue';
import InfoLoader2 from '../../components/InfoLoader2.vue';
  export default {
    name: 'Scores',
    components: {
        HeaderVue,
        SubjectScoresModal,
        PremiumInfo,
        RemarkModal,
        MotorModal,
        InfoLoader2
    },
    mounted() {
       this.fetchTerms()
    },
    data: () => ({
        authToken:sessionStorage.getItem('authToken') ?? null,
        accessToken:sessionStorage.getItem('accessToken') ?? null,
        school:localStorage.getItem('school') ? JSON.parse(localStorage.getItem('school')) : {},
        userType:sessionStorage.getItem('utpc') ?? null,
        scoresEntryForm: false,
        scoresRemarksForm: false,
        scoresMotorsForm: false,
        form:{},
        passport: false,
        classes:[],
        terms:[],
        fetchingContent:true
    }),
    metaInfo: {
        meta: [
            { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
        ]
    },
    methods: {
        showSEForm (){
            this.scoresEntryForm = !this.scoresEntryForm
            this.scoresRemarksForm = false
            this.scoresMotorsForm = false
        },
        showREForm (){
            this.scoresEntryForm = false
            this.scoresRemarksForm = !this.scoresRemarksForm
            this.scoresMotorsForm = false
        },
        showPEForm (){
            this.scoresEntryForm = false
            this.scoresRemarksForm = false
            this.scoresMotorsForm = !this.scoresMotorsForm
        },
        fetchTerms () {
            axios.get(process.env.VUE_APP_BASEURL+'/parameters?school_id='+this.school.school_id,
            {
                headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken,
                        'access_token': this.accessToken,
                    }
            })
            .then((response) => {
                this.terms =  response.data.data   
                if (this.terms.length == 0) {
                    this.$toast.info('You will need to set a session/term first!', {
                            timeout: 5000
                        }); 
                    this.$router.push('terms')
                }
                this.fetchClasses()         
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    localStorage.removeItem('authToken')
                }
                this.$toast.error(error.response ? error.response.data.message : 'APP is temporarily unavailable. Wait or Contact us!', {
                    timeout: 5000
                });
            })
            .finally(() => {
            });
        },
        fetchClasses () {
            axios.get(process.env.VUE_APP_BASEURL+'/classes?school_id='+this.school.school_id,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken,
                    'access_token': this.accessToken,
                }
            })
            .then((response) => {
                this.classes =  response.data.data                  
                this.fetchingContent = false;
                 if (this.classes.length == 0) {
                    this.$toast.info('You will need to set classes first!', {
                            timeout: 5000
                        }); 
                    this.$router.push('classes')
                }           
            })
            .catch((error) => {
                console.log(error)
                this.$toast.error(error.response ? error.response.data.message : 'Internet Network Not Available!', {
                    timeout: 5000
                });
            })
            .finally(() => {
            });
        },
    },
  }
</script>
