<template>
    <v-row>
        <v-col cols="12">
            <v-form>
                <v-container>
                <v-row>
                    <v-col cols="12" md="4">
                    <v-select
                    :items="terms.filter((item)=>{return item.current_term == true})"
                    item-text="term_session"
                    item-value="parameter_id"
                    label="Select Term:"
                    v-model="form.parameter_id"
                    @change="fetchStudents()"
                    outlined
                    :rules="[v => !!v || 'Term need to be selected!']"
                    required
                    ></v-select>
                    </v-col>

                    <v-col cols="12" md="4">
                    <v-select
                    :items="classes"
                    item-text="class"
                    item-value="class_id"
                    label="Select Class:"
                    v-model="form.clasx_id"
                    outlined
                    :rules="[v => !!v || 'Class need to be selected!']"
                    required
                    @change="fetchStudents()"
                    ></v-select>
                    </v-col>

                    <v-col cols="12" md="4">
                    <v-select
                    :items="subjects"
                    item-text="subject"
                    item-value="subject_id"
                    label="Select Subject:"
                    v-model="form.subject_id"
                    @change="emitForm"
                    outlined
                    :rules="[v => !!v || 'Subject need to be selected!']"
                    required
                    ></v-select>
                    </v-col>
                    <v-col
                    cols="12"
                    md="12"
                    v-if="school.school_id == '5e1e7978-e58e-4f95-8269-3069cbf5f402'"
                    >
                    <v-select
                    :items="['2','3','4']"
                    label="How many Continuous Assessment"
                    v-model="cacount"
                    @change="updateCaCount()"
                    outlined
                    required
                    ></v-select>
                    </v-col>
                    <v-col
                    cols="12"
                    md="12"
                    v-if="1==0"
                    >
                    <v-select
                    :items="students"
                    item-text="name"
                    item-value="scs_id"
                    label="Select Student(s):"
                    v-model="form.student_ids"
                    @change="emitForm"
                    multiple
                    outlined
                    :rules="[(v => !!v) && ((v) =>  v.length>0) || 'Students need to be selected!']"
                    required
                    ></v-select>
                    </v-col>
                </v-row>
                </v-container>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios';
export default {
    name: 'FindScoreRecord',
    props:[
        'authToken', 
        'accessToken', 
        'school',
        'terms',
        'classes',
    ],
    data () {
        return {
            students: [],
            subjects: [],
            form: {},
            cacount: this.school.kinds_of_ca,
        }
    },
    methods:{
        emitForm (){
            this.$emit('parameterId', this.form.parameter_id)
            this.$emit('clasxId', this.form.clasx_id)
            this.$emit('subjectId', this.form.subject_id)
        },
        updateCaCount(){
            this.school.kinds_of_ca = this.cacount
            localStorage.school =  JSON.stringify(this.school)
        },
        fetchStudents (){
            if (this.form.parameter_id && this.form.clasx_id) { 
                let loader = this.$loading.show()
                axios.get(process.env.VUE_APP_BASEURL+'/students?school_id='+this.school.school_id+'&class_id='+this.form.clasx_id+'&parameter_id='+this.form.parameter_id,
                {
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken,
                        'access_token': this.accessToken,
                    }
                })
                .then((response) => {
                    this.students =  response.data.data 
                    this.$emit('students', this.students) 
                    if (this.students.length == 0) {
                        this.$toast.info('No Students in selected class. ADD or PROMOTE students to class!', {
                                timeout: 5000
                            }); 
                        this.$router.push('students')
                    }
                    this.fetchSubjects(this.form.clasx_id)   
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.emitForm()
                    loader.hide()
                });
            }
        },
        fetchSubjects (classId) {
            axios.get(process.env.VUE_APP_BASEURL+'/subjects?school_id='+this.school.school_id+'&class_id='+classId,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken,
                    'access_token': this.accessToken,
                }
            })
            .then((response) => {
                this.subjects =  response.data.data 
                this.form.student_ids = []           
                this.form.subject_id = null
                if (this.subjects.length == 0) {
                    this.$toast.info('No Subjects registered to class. ADD or Update subjects!', {
                            timeout: 5000
                        }); 
                    this.$router.push('subjects')
                }
                this.$emit('subjects', this.subjects)         
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
            });
        },
    }
}
</script>

<style scoped>
</style>