<template>
    <v-row>
        <v-col cols="12" v-for="(stu, i) in students" :key="i">
            <h3 class="h6 blue--text">{{stu.name}}</h3>
            <small>{{stu.student_regno}}</small>
            <v-form v-model="storeF">
                <v-simple-table>
                    <thead>
                        <tr>
                        <th>CA 1 </th>
                        <th v-if="school.kinds_of_ca > 1">CA 2 </th>
                        <th v-if="school.kinds_of_ca > 2">CA 3 </th>
                        <th v-if="school.kinds_of_ca > 3">CA 4 </th>
                        <th>Exam </th>
                        </tr>
                    </thead>
                    <tbody v-if="scores">
                        <tr
                        v-for="score in scores.scores.filter((item)=>{return item.scs_id == stu.scs_id})"
                        :key="score.id"
                        >
                        <td>
                            <v-chip v-if="editForm" outlined><input type="number" onwheel="this.blur()" rounded
                            v-model="score.firstca" @change="emitForm" class="small-input" size="0.1" min="0" max="40"/></v-chip>
                        </td>
                        <td v-if="school.kinds_of_ca > 1">
                            <v-chip v-if="editForm" outlined><input type="number" onwheel="this.blur()" rounded
                            v-model="score.secondca" @change="emitForm" class="small-input" size="0.1" min="0" max="40"/></v-chip>
                        </td>
                        <td v-if="school.kinds_of_ca > 2">
                            <v-chip v-if="editForm" outlined><input type="number" onwheel="this.blur()" rounded
                            v-model="score.project" @change="emitForm" class="small-input" size="0.1" min="0" max="40"/></v-chip>
                        </td>
                        <td v-if="school.kinds_of_ca > 3">
                            <v-chip v-if="editForm" outlined><input type="number" onwheel="this.blur()" rounded
                            v-model="score.classwork" @change="emitForm" class="small-input" size="0.1" min="0" max="40"/></v-chip>
                        </td>
                        <td>
                            <v-chip v-if="editForm" outlined><input type="number" onwheel="this.blur()" rounded
                            v-model="score.exam" @change="emitForm" class="small-input" size="0.1" min="0" max="70"/></v-chip>
                        </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios';
export default {
    name: 'ScoreEntry',
    props:[
        'authToken', 
        'accessToken',
        'school',
        'students',
        'term',
        'clasx',
        'subject',
    ],
    data () {
        return {
            storeF:true,
            editForm: true,
            scores:{},
        }
    },
    mounted() {
       this.getScores()
       this.emitForm()
    },
    methods:{
        emitForm (){
            this.$emit('updatedScoreRecords', this.scores.scores)
        },
        getScores (){
            let loader = this.$loading.show()
            axios.get(process.env.VUE_APP_BASEURL+'/scores?school_id='+this.school.school_id+'&clasx_id='+this.clasx+'&parameter_id='+this.term+'&subject_id='+this.subject,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken,
                    'access_token': this.accessToken,
                }
            })
            .then((response) => {
                this.scores =  response.data.data  
                console.log(this.scores)       
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
    }
}
</script>

<style scoped>
    .small-input{
       width: 50px !important; 
    }
</style>
<style>
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
</style>