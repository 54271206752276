<template>
    <v-row>
        <v-col cols="12" v-for="(stu, i) in students" :key="i">
            <h3 class="h6 blue--text">{{stu.name}}</h3>
            <small>{{stu.student_regno}}</small>
            <v-form v-model="storeF">
                <v-simple-table dense>
                    <tbody v-for="motor in records.filter((item)=>{return item.scs_id == stu.scs_id})"
                        :key="motor.motor_id">
                        <tr>
                            <td width="200px">
                            Punctuality 
                            </td>                        
                            <td>
                                <v-text-field type="number" onwheel="this.blur()"
                                v-model="motor.m1" 
                                @change="emitForm" 
                                class="small-input" 
                                size="1" min="0" max="10"
                                filled dense>
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Politeness 
                            </td>                        
                            <td>
                                <v-text-field type="number" onwheel="this.blur()"
                                v-model="motor.m2" 
                                @change="emitForm" 
                                class="small-input" 
                                size="1" min="0" max="10"
                                filled dense>
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Neatness 
                            </td>                        
                            <td>
                                <v-text-field type="number" onwheel="this.blur()"
                                v-model="motor.m3" 
                                @change="emitForm" 
                                class="small-input" 
                                size="1" min="0" max="10"
                                filled dense>
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Honesty 
                            </td>                        
                            <td>
                                <v-text-field type="number" onwheel="this.blur()"
                                v-model="motor.m4" 
                                @change="emitForm" 
                                class="small-input" 
                                size="1" min="0" max="10"
                                filled dense>
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Leadership 
                            </td>                        
                            <td>
                                <v-text-field type="number" onwheel="this.blur()"
                                v-model="motor.m5" 
                                @change="emitForm" 
                                class="small-input" 
                                size="1" min="0" max="10"
                                filled dense>
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Cooperation 
                            </td>                        
                            <td>
                                <v-text-field type="number" onwheel="this.blur()"
                                v-model="motor.m6" 
                                @change="emitForm" 
                                class="small-input" 
                                size="1" min="0" max="10"
                                filled dense>
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Attentiveness 
                            </td>                        
                            <td>
                                <v-text-field type="number" onwheel="this.blur()"
                                v-model="motor.m7" 
                                @change="emitForm" 
                                class="small-input" 
                                size="1" min="0" max="10"
                                filled dense>
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Perserverance 
                            </td>                        
                            <td>
                                <v-text-field type="number" onwheel="this.blur()"
                                v-model="motor.m8" 
                                @change="emitForm" 
                                class="small-input" 
                                size="1" min="0" max="10"
                                filled dense>
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Attitude to Work 
                            </td>                        
                            <td>
                                <v-text-field type="number" onwheel="this.blur()"
                                v-model="motor.m9" 
                                @change="emitForm" 
                                class="small-input" 
                                size="1" min="0" max="10"
                                filled dense>
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Handwriting 
                            </td>                        
                            <td>
                                <v-text-field type="number" onwheel="this.blur()"
                                v-model="motor.m10" 
                                @change="emitForm" 
                                class="small-input" 
                                size="1" min="0" max="10"
                                filled dense>
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Verbal Fluency 
                            </td>                        
                            <td>
                                <v-text-field type="number" onwheel="this.blur()"
                                v-model="motor.m11" 
                                @change="emitForm" 
                                class="small-input" 
                                size="1" min="0" max="10"
                                filled dense>
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Sports 
                            </td>                        
                            <td>
                                <v-text-field type="number" onwheel="this.blur()"
                                v-model="motor.m12" 
                                @change="emitForm" 
                                class="small-input" 
                                size="1" min="0" max="10"
                                filled dense>
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Handling Tools 
                            </td>                        
                            <td>
                                <v-text-field type="number" onwheel="this.blur()"
                                v-model="motor.m13" 
                                @change="emitForm" 
                                class="small-input" 
                                size="1" min="0" max="10"
                                filled dense>
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Drawing &amp; Painting 
                            </td>                        
                            <td>
                                <v-text-field type="number" onwheel="this.blur()"
                                v-model="motor.m14" 
                                @change="emitForm" 
                                class="small-input" 
                                size="1" min="0" max="10"
                                filled dense>
                                </v-text-field>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'MotorEntry',
    props:[
        'students',
        'records',
    ],
    data () {
        return {
            storeF:true,
            editForm: true,
        }
    },
    mounted(){
        this.emitForm()
    },
    methods:{
        emitForm (){
            this.$emit('updatedMotorRecords', this.records)
        },
    }
}
</script>

<style scoped>
    .small-input{
        margin-top: 30px;
       width: 70px !important; 
    }
</style>